<template>
    <dash-page-new
        v-if="$store.state.auth.userType === 'superadmin'"
        :title="projectName"
        subtitle="Documentation"
        :root="$t('Dashboard')"
    >

      <template #header_action>
        <ws-select v-model="selectedLang"  :items="[{ text : 'UA' , value : 'ua' }, { text : 'RU' , value : 'ru' } , { text : 'EN' , value : 'en' }]" ></ws-select>
      </template>

      <template #header_title_edit>
        <ft-select v-model="project" :items="projectsSelect">
          <v-btn :color="wsACCENT" icon>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </ft-select>
      </template>


      <template #default>

        <v-simple-table class="wsHoverLight mt-6">
          <tbody>
          <template v-for="(item, i) in categories">
            <tr :key="'category' + i">
              <td>
                <h3 :style="`color : ${wsACCENT}`">{{  item.text  }}</h3>
              </td>
              <td align="left">
                <div class="d-flex align-center justify-end">
                  <h5 class="mr-4"> {{ item.is_published ? 'Published' : 'Not Published' }}</h5>
                  <v-btn @click="openEditCategory(item)" icon> <v-icon :color="wsATTENTION">mdi-pencil-circle</v-icon></v-btn>
                </div>
              </td>
            </tr>


            <template v-for="(topic, k) in item.topics">
              <tr class="pointer" @click="$router.push(localeLink('dashboard/documentation/' + topic.value))"  :key="'topic' + i + k">
                <td class="linkHover" colspan="3">
                  <router-link class=" noUnderline black--text" :to="localeLink('dashboard/documentation/' + topic.value)">
                    <h3 class="pl-6 ">{{  topic.text  }}</h3>
                  </router-link>
                </td>
              </tr>
            </template>
            <tr v-if="item.sub_categories.length === 0" :key="'addTopicRoot_' + i ">
              <td align="right" colspan="3">
                  <v-btn @click="addTopic(item)" block class="noCaps" dark :color="wsACCENT">Add ARTICLE</v-btn>
              </td>
            </tr>


            <template v-for="(child, j) in item.sub_categories">

              <tr :key="'sub_category' + i + j">
                <td>
                  <h3 :style="`color : ${wsACCENT}`" class="pl-6">{{  child.text  }}</h3>
                </td>
                <td class="text-no-wrap" width="10px" align="right">
                  <div class="d-flex align-center justify-end">
                    <h5 class="mr-4 text-no-wrap"> {{ child.is_published ? 'Published' : 'Not Published' }}</h5>
                    <v-btn @click="openEditCategory(child)" icon> <v-icon :color="wsATTENTION">mdi-pencil-circle</v-icon></v-btn>
                  </div>
                </td>
              </tr>

              <template v-for="(topic, l) in child.topics">
                <tr class="pointer" @click="$router.push(localeLink('dashboard/documentation/' + topic.value))"  :key="'topic_2' + i + j + l">
                  <td class="linkHover" colspan="3">
                    <router-link class=" noUnderline black--text"  :to="localeLink('dashboard/documentation/' + topic.value)">
                      <h3 class="pl-12 linkHover">{{  topic.text  }}</h3>
                    </router-link>
                  </td>
                </tr>
              </template>
              <tr :key="'addTopic_' + j + i ">
                <td colspan="3">
                  <v-btn @click="addTopic(child)" block class="noCaps" dark :color="wsACCENT">Add ARTICLE</v-btn>
                </td>
              </tr>

            </template>
          </template>


          </tbody>
        </v-simple-table>

        <v-btn dark class="noCaps mt-6" block large :color="wsACCENT" @click="openAddCategory">
          <v-icon>mdi-plus</v-icon>
          {{ $t('AddCategory') }}
        </v-btn>

      </template>

      <template #dialog>
        <ws-dialog
            @save="saveCategory"
            :title="editCategory ? 'Edit Category' : 'Add Category' "
            v-model="displayCategoryDialog">
          <ws-text-field v-model="categoryEntityData.name" label="Name" placeholder="Enter category name"> Name </ws-text-field>
          <ws-select
              v-if="!editCategory"
              v-model="categoryEntityData.parent"
              :items="categories"
              class="mt-5"
              label="Parent Category"
              placeholder="Choose parent category"
          />
          <ws-select
              v-if="editCategory"
              v-model="categoryEntityData.is_published"
              :items="CHOICE_ACTIVE"
              class="mt-5"
              label="Is Published"
          />
        </ws-dialog>
      </template>


    </dash-page-new>
</template>

<script>

import {mapActions} from "vuex";
export default {
  name: "dashDocumentation",
  components : {
  },
  data() {
    return {
      categories : [],
      editCategory : false,
      displayCategoryDialog : false,
      project : 'westudy',
      categoryEntityData : {},
      //lang
      selectedLang : 'ua',
      projectsSelect : [
        { text : "WeStudy" , value : 'westudy' },
        { text : "WeStudy University" , value : 'westudy_university' },
        { text : "Fine Trading" , value : 'fine' }
      ]

    }
  },
  computed : {

    projectName() {
      return this.projectsSelect.find(el=> el.value === this.project).text
    }

  },
  watch : {
    project() {
      this.initPage()
    },
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('documentation', [
        'ADD_EDIT_CATEGORY',
        'GET_CATEGORIES',
        'ADD_TOPIC',
    ]),

    openEditCategory(category) {

      this.editCategory = true
      this.categoryEntityData = {
        uuid : category.value,
        project : this.project,
        lang : this.selectedLang,
        name : category.text,
        parent : category.parent,
        is_published : category.is_published
      }
      this.displayCategoryDialog = true
    },

    openAddCategory() {
      this.editCategory = false
      this.categoryEntityData = {
        project : this.project,
        lang : this.selectedLang
      }
      this.displayCategoryDialog = true

    },

    async saveCategory() {
      let result = await this.ADD_EDIT_CATEGORY(this.categoryEntityData)
      if ( !result ) {
        return
      }
      if (!this.editCategory ) {
        this.categories.push(result)
      } else {
        let index = this.categories.findIndex(el => el.value === this.categoryEntityData.uuid)

        if ( index === -1 ) {
          index = this.categories.findIndex(el => el.value === this.categoryEntityData.parent)
          let childIndex = this.categories[index].sub_categories.findIndex(el => el.value === this.categoryEntityData.uuid)
          this.categories[index].sub_categories[childIndex].text = this.categoryEntityData.name
          this.categories[index].sub_categories[childIndex].is_published = this.categoryEntityData.is_published
        } else {
          this.categories[index].text = this.categoryEntityData.name
          this.categories[index].is_published = this.categoryEntityData.is_published
        }

        this.categories = JSON.parse(JSON.stringify(this.categories))
      }

      this.displayCategoryDialog = false
    },
    async addTopic(category) {
      this.notify('AddTopic: ' + category.value )
      let data = {
        category : category.value,
        lang : this.selectedLang
      }
      let result = await this.ADD_TOPIC(data)
      if ( !result ) {
        return
      }
      category.topics.push(result)
    },

    async initPage() {
      let result = await this.GET_CATEGORIES({ lang : this.selectedLang , project : this.project} )
      if (!result || result === true) {
        this.categories = []
        return
      }
      this.categories = result
    },


  },
  mounted() {
    this.project = this.$store.state.project;
    this.initPage()
  }

}
</script>

<style scoped>

</style>